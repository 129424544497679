.ai__features-container__feature {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;

  margin: 1rem;
}

.ai__features-container__feature-title {
  flex: 1;
  max-width: 180px;
}

.ai__features-container__feature-title h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 18px;
  line-height: 75px;
  letter-spacing: -0.04em;
  color: #fff;
}

.ai__features-container__feature-title div {
  width: 38px;
  height: 3px;
  background: var(--gradient-bar);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

.ai__features-container__feature-text {
  flex: 2;
  max-width: 70%;
  display: flex;
}

.ai__features-container__feature-text p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-text);
}

@media screen and (max-width: 990px) {
  .ai__features {
    flex-direction: column;
  }

  .ai__features-heading {
    margin: 0 0 2rem 0;
  }
}

@media screen and (max-width: 550px) {
  .ai__features-heading h1 {
    font-size: 28px;
    line-height: 38px;
  }
}
